import React from 'react'
import About from './About'
import Blog from './Blog'
import Footer from './Footer'
import Modal from './Modal'
import Navbar from './Navbar'
import ProNum from './ProNum'
import Review from './Review'
import Slider from './Slider'
import Team from './Team'
import WhatWeDo from './WhatWeDo'
import Work from './Work'

const MainPage = () => {
  return (
    <>
   <Navbar />
   <Slider />
  <About />
  <ProNum />
  <WhatWeDo />
  <Work />
  <Team />
  <Review />
  <Blog />
  <Footer />
  {/* Modal */}
  <Modal />
</>

  )
}

export default MainPage
import React from 'react'

const WhatWeDo = () => {
  return (
    <>
    
    <section id="services" className="text-center">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="intro">
            <h6>Our Services</h6>
            <h1>What We Do?</h1>
            <p className="mx-auto">
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old
            </p>
          </div>
        </div>
      </div>
      <div className="row g-4">
        <div className="col-lg-4 col-md-6">
          <div className="service">
            <img src="/static/img/icon1.png" alt="" />
            <h5>Digital Marketing</h5>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service">
            <img src="/static/img/icon2.png" alt="" />
            <h5>
 Designing</h5>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service">
            <img src="/static/img/icon3.png" alt="" />
            <h5>Buisness consulting</h5>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service">
            <img src="/static/img/icon4.png" alt="" />
            <h5>Videography</h5>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service">
            <img src="/static/img/icon5.png" alt="" />
            <h5>Brand Identity</h5>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service">
            <img src="/static/img/icon6.png" alt="" />
            <h5>Ethical Hacking</h5>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default WhatWeDo
import React from "react";

const Review = () => {
  return (
    <>
      <section className="bg-light" id="reviews">
        <div className="owl-theme owl-carousel reviews-slider container">
          <div className="review">
            <div className="person">
              <img src="/static/img/team_1.jpg" alt="" />
              <h5>Ralph Edwards</h5>
              <small>Market Development Manager</small>
            </div>
            <h3>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut
              quis, rem culpa labore voluptate ullam! In, nostrum. Dicta, vero
              nihil. Delectus minus vitae rerum voluptatum, excepturi incidunt
              ut, enim nam exercitationem optio ducimus!
            </h3>
            <div className="stars">
              <i className="bx bxs-star" />
              <i className="bx bxs-star" />
              <i className="bx bxs-star" />
              <i className="bx bxs-star" />
              <i className="bx bxs-star-half" />
            </div>
            <i className="bx bxs-quote-alt-left" />
          </div>
          <div className="review">
            <div className="person">
              <img src="/static/img/team_2.jpg" alt="" />
              <h5>Ralph Edwards</h5>
              <small>Market Development Manager</small>
            </div>
            <h3>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut
              quis, rem culpa labore voluptate ullam! In, nostrum. Dicta, vero
              nihil. Delectus minus vitae rerum voluptatum, excepturi incidunt
              ut, enim nam exercitationem optio ducimus!
            </h3>
            <div className="stars">
              <i className="bx bxs-star" />
              <i className="bx bxs-star" />
              <i className="bx bxs-star" />
              <i className="bx bxs-star" />
              <i className="bx bxs-star-half" />
            </div>
            <i className="bx bxs-quote-alt-left" />
          </div>
          <div className="review">
            <div className="person">
              <img src="/static/img/team_3.jpg" alt="" />
              <h5>Ralph Edwards</h5>
              <small>Market Development Manager</small>
            </div>
            <h3>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut
              quis, rem culpa labore voluptate ullam! In, nostrum. Dicta, vero
              nihil. Delectus minus vitae rerum voluptatum, excepturi incidunt
              ut, enim nam exercitationem optio ducimus!
            </h3>
            <div className="stars">
              <i className="bx bxs-star" />
              <i className="bx bxs-star" />
              <i className="bx bxs-star" />
              <i className="bx bxs-star" />
              <i className="bx bxs-star-half" />
            </div>
            <i className="bx bxs-quote-alt-left" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Review;

import React from "react";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-top text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 text-center">
                <h4 className="navbar-brand">
                  qamarsoftsolutions<span className="dot">.</span>
                </h4>
                <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from
                </p>
                <div className="col-auto social-icons">
                  <a href="#">
                    <i className="bx bxl-facebook" />
                  </a>
                  <a href="#">
                    <i className="bx bxl-twitter" />
                  </a>
                  <a href="#">
                    <i className="bx bxl-instagram" />
                  </a>
                  <a href="#">
                    <i className="bx bxl-pinterest" />
                  </a>
                </div>
                <div className="col-auto conditions-section">
                  <a href="#">privacy</a>
                  <a href="#">terms</a>
                  <a href="#">disclaimer</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom text-center">
          <p className="mb-0">Copyright vicpra 2022. All rights Reserved</p>{" "}
          Distributed By <a hrefs="https://themewagon.com">ThemeWagon</a>
        </div>
      </footer>
    </>
  );
};

export default Footer;

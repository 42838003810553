import React from 'react'

const About = () => {
  return (
    <>
    <section id="about">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-5 py-5">
          <div className="row">
            <div className="col-12">
              <div className="info-box">
                <img src="/static/img/icon6.png" alt="" />
                <div className="ms-4">
                  <h5>Digital Marketing</h5>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="info-box">
                <img src="/static/img/icon4.png" alt="" />
                <div className="ms-4">
                  <h5>E-mail Marketing</h5>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="info-box">
                <img src="/static/img/icon5.png" alt="" />
                <div className="ms-4">
                  <h5>Buisness Marketing</h5>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <img src="/static/img/about.png" alt="" />
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default About
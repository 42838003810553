import React from "react";

const TopNabar = () => {
  return (
    <>
      <div className="top-nav" id="home">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-auto">
              <p>
                {" "}
                <i className="bx bxs-envelope" />{" "}
                <a
                  href="mailto:email@example.com"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  {" "}
                  info@qamarsoftsolutions.com
                </a>
              </p>
              <p>
                {" "}
                <i className="bx bxs-phone-call" /> +1 (321) 710 8967
              </p>
            </div>
            <div className="col-auto social-icons">
              <a href="#">
                <i className="bx bxl-facebook" />
              </a>
              <a href="#">
                <i className="bx bxl-twitter" />
              </a>
              <a href="#">
                <i className="bx bxl-instagram" />
              </a>
              <a href="#">
                <i className="bx bxl-pinterest" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNabar;
